import {React} from 'react';

export function SlideQuestion({question}) {
    return question && (
        <div className='d-flex box-question mx-auto mt-3 mb-5'>
            <img className="rounded-circle flex-shrink-0 me-4 me-md-5" src="/images/nelly.jpg" alt="Nelly" height="42" width="42"/>
            <div className="mt-3">
                <p className="mb-0" dangerouslySetInnerHTML={{__html: question}}></p>
            </div>
        </div>
    )
}

export function SlideMessage({message}) {
    return message && (
        <div className="alert alert-secondary" role="alert">
            {message}
        </div>
    )
}
