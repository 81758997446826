import {React} from 'react';

export default function header () {
    return (
        <header className="header">
            <div className="container">
                <img className="logo" src="/images/outre-mer-immobilier.svg" alt="outre-mer-immobilier.re"/>
            </div>
        </header>
    )
}